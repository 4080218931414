import React from "react";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch,
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./style.css";
import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields,
} from "./config/config-helper";

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase,
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig(),
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true,
};

export default function App() {
  return (
    <SearchProvider config={config}>
      <WithSearch
        mapContextToProps={({ wasSearched, results }) => ({
          wasSearched,
          results,
        })}
      >
        {({ wasSearched, results }) => {
          return (
            <div className="App">
              <ErrorBoundary>
                <Layout
                  header={
                    <SearchBox
                      className="sui-search-box"
                      view={({ value, onChange, onSubmit }) => (
                        <form
                          className="sui-search-box__wrapper"
                          onSubmit={onSubmit}
                        >
                          <input
                            className="sui-search-box__text-input"
                            type="text"
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                          />
                          <input
                            className="sui-search-box__submit"
                            type="submit"
                            value="ابحث"
                          />
                        </form>
                      )}
                    />
                  }
                  sideContent={
                    <div>
                      <Facet
                        field="Language"
                        label="اللغة"
                        filterType="any"
                        isFilterable={false}
                      />
                      <Facet
                        field="Name"
                        show = "20"
                        label="كتاب"
                        filterType="any"
                        isFilterable={false}
                      />
                      <Facet
                        field="Author"
                        show = "20"
                        label="المؤلف"
                        filterType="any"
                        isFilterable={false}
                      />
                    </div>
                  }
                  bodyContent={
                    <div className="wrapper">
                      {results.map((result) => (
                        <div className="main-result">
                          <div className="title-field">
                            {" "}
                            من
                            "
                            <span
                            className="book"
                              dangerouslySetInnerHTML={{
                                __html: result.Name.raw,
                              }}
                            />
                            " لـ "
                            <span
                            className="author"
                              dangerouslySetInnerHTML={{
                                __html: result.Author.raw,
                              }}
                            />
                            "
                          </div>
                          <div className="flex">
                            <div className="flex-right">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: result.Original.snippet,
                                }}
                              />
                            </div>
                            <div className="flex-left">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: result.Translation.snippet,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && (
                        <PagingInfo
                          view={({
                            start,
                            end,
                          }: {
                            start: number;
                            end: number;
                          }) => (
                            <div className="paging-info">
                              <strong>
                                النتائج من {start} - إلى {end}
                              </strong>
                            </div>
                          )}
                        />
                      )}
                      {wasSearched && (
                        <ResultsPerPage options={[5, 20, 50, 70]} />
                      )}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
